(function () {
	'use strict';

	angular
		.module('app')
		.factory('seedcodeDev', ['firebaseIO', 'manageUser', seedcodeDev]);

	function seedcodeDev(firebaseIO, manageUser) {
		let accessCredentials;
		return {
			init: init,
			getAccessCredentials: getAccessCredentials,

			createGroupToken: createGroupToken,
			getIDToken: getIDToken,
			isTrialExpired: isTrialExpired,
			findUser: findUser,
			removeUserSettings: removeUserSettings,
			setDefaultActions: setDefaultActions,
		};

		function init(callback) {
			firebaseIO.getUserData(null, 'seedcode', processUserData);
			function processUserData(seedcodeUserData) {
				if (seedcodeUserData?.token && seedcodeUserData?.role) {
					// We have a seedcode user and can let them in
					accessCredentials = seedcodeUserData;
					callback(true);
				} else {
					// Not authorized
					callback(false);
				}
			}
		}

		function getAccessCredentials() {
			return accessCredentials;
		}

		// Todo: Delete the following functions because we don't want this run on the client
		function createGroupToken(memberID, groupID) {
			var groupToken = manageUser.createGroupToken(memberID, groupID);
			console.log('groupToken', groupToken);
		}

		function getIDToken(callback) {
			firebase
				.auth()
				.currentUser.getIdToken(/* forceRefresh */ true)
				.then(function (idToken) {
					console.log(firebase.auth().currentUser);
					callback(idToken);
					console.log(idToken);
					// Send token to your backend via HTTPS
					// ...
				})
				.catch(function (error) {
					console.log('There was a problem getting id token');
					// Handle error
				});
		}

		function getCurrentUser(callback) {
			firebase.auth().currentUser;
		}

		function removeUserSettings(userID) {
			if (!userID) {
				return;
			}
			console.log('remove settings', 'users/' + userID + '/settings');
			firebaseIO.setData(
				'users/' + userID + '/settings',
				'desktop-salesforce',
				null,
				null,
				null,
				null
			);
		}

		function isTrialExpired(groupID, currentDate, callback) {
			firebaseIO.getData(
				'groups/' + groupID + '/settings/activationHash',
				function (hash) {
					if (!hash || hash === 'new') {
						callback(false);
						return;
					}
					console.log('found', hash);

					var type = crypt.runGetHashProperty(hash, 'type');
					var hashDate = Number(
						crypt.runGetHashProperty(hash, 'hashDate')
					);
					var expiresDate = Number(
						crypt.runGetHashProperty(hash, 'expiresDate')
					);
					var email = crypt.runGetHashProperty(hash, 'email');
					console.log('groupID', groupID);
					console.log('type', type);
					console.log(
						'expires',
						currentDate.diff(expiresDate, 'days')
					);
					console.log('end');
					callback(
						type === 'trial' &&
							currentDate.diff(expiresDate, 'days') >= 45,
						groupID
					);
				}
			);
		}

		//Find and return user and group data based on account email address
		function findUser(accountName, callback) {
			var user;
			//Find user record by looping through all users and matching account name
			firebaseIO.getData('users/', getUser, null, null);

			function getUser(users) {
				for (var property in users) {
					if (accountName === users[property].account) {
						user = users[property].user;
					}
				}
				if (user) {
					firebaseIO.getData(
						'groups/' + user.group.id,
						function (group) {
							if (callback) {
								callback(user, group);
							}
						},
						null,
						null
					);
				}
			}
		}

		function setDefaultActions() {
			getGroups(process);

			function process(groups) {
				var count = 0;

				for (var property in groups) {
					for (var sourceProperty in groups[property].sources) {
						var source = groups[property].sources[sourceProperty];
						if (source.eventActions) {
							for (var actionProperty in source.eventActions) {
								var action =
									source.eventActions[actionProperty];
								var fullPath =
									'groups/' +
									property +
									'/sources/' +
									source.id +
									'/eventActions/' +
									action.id +
									'/';
								firebaseIO.setData(
									fullPath,
									'eventType',
									{editable: true},
									null,
									null,
									null
								);
								count++;
							}
						}
					}
				}
				alert('complete');
			}
		}
	}
})();
